import React from "react";

const Head = () => {
  return (
    <>
      <section className="head">
        <div className="container flexSB">
          <div className="logo">
            <a href="/">
              <img src="/images/logo.png" alt="" style={{ width: "170px" }} />
            </a>
          </div>

          <div className="social">
            <a
              href="https://www.facebook.com/eManageritinstitute"
              target="blank"
            >
              <i className="fab fa-facebook-f icon"></i>
            </a>
            <i className="fab fa-youtube icon"></i>
          </div>
        </div>
      </section>
    </>
  );
};

export default Head;
